<template>
    <div class="signin-body">
        <div class="form-signin">
        <!--
            <img class="mb-4" src="../../assets/logos/bootstrap/bootstrap-solid.svg" alt="" width="72" height="72">
        -->
            <img class="mb-4" src="@/assets/logo.png" alt="" width="160">
            <h1 class="h3 mb-3 font-weight-normal">Reset your password</h1>

            <!-- Display server error messages, from server validation -->
            <!-- Diplay alert message received from backend -->
            <AltertMessage
                v-bind:data="formUtilsComp.state.form.response"
            ></AltertMessage>

            <!-- Display server exceptions, from database -->
            <ExceptionMessage
                v-bind:exception="formUtilsComp.state.form.exception"
            ></ExceptionMessage>

            <div class="form-group mb-3">
                <input
                    v-model="authComp.state.form.body.email"
                    @keyup.enter="resetPassword()"
                    type="email"
                    id="inputEmail"
                    class="form-control"
                    v-bind:class="formUtilsComp.isEmailValid(authComp.state.form.body.email) ? 'is-valid' : 'is-invalid'"
                    placeholder="Email address"
                    required autofocus
                >
                <div v-if="!formUtilsComp.isEmailValid(authComp.state.form.body.email)" class="text-info text-sm">Please enter a valid email address</div>
                <!-- Diplay alert message received from backend -->
                <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.email"></ValidationErrors>
            </div>

            <div class="form-group mb-3">
                <input
                    v-model="authComp.state.form.body.password"
                    :type="authComp.state.form.show_password ? 'text' : 'password'"
                    @keyup.enter="resetPassword()"
                    id="inputPassword"
                    class="form-control"
                    v-bind:class="formUtilsComp.isPasswordEmpty(authComp.state.form.body.password) ? 'is-invalid' : 'is-valid'"
                    placeholder="Password" required
                >
                <div v-if="formUtilsComp.isPasswordEmpty(authComp.state.form.body.password)" class="text-info text-sm">Please enter your password</div>
                <!-- Diplay alert message received from backend -->
                <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.password"></ValidationErrors>
            </div>

            <div class="form-group mb-3">
                <input
                    v-model="authComp.state.form.body.password_confirmation"
                    :type="authComp.state.form.show_password ? 'text' : 'password'"
                    @keyup.enter="resetPassword()"
                    id="inputPasswordConfirmation"
                    class="form-control"
                    v-bind:class="formUtilsComp.passwordsMatch(authComp.state.form.body.password, authComp.state.form.body.password_confirmation) ? 'is-valid' : 'is-invalid'"
                    placeholder="Password confirmation" required
                >
                <div v-if="!formUtilsComp.passwordsMatch(authComp.state.form.body.password, authComp.state.form.body.password_confirmation)" class="text-info text-sm">Passwords do not match</div>
                <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.password_confirmation"></ValidationErrors>
            </div>

            <div class="form-group">
                <div class="checkbox mb-1">
                    <label>
                        <input
                            v-model="authComp.state.form.show_password"
                            type="checkbox"
                            value="show-password"
                        > Show password
                    </label>
                </div>
            </div>

            <button
                v-if="formUtilsComp.passwordsMatch(authComp.state.form.body.password, authComp.state.form.body.password_confirmation)"
                @click="resetPassword"
                class="btn btn-lg btn-primary btn-block"
            >Update</button>

<!--
            <button
                @click="resetPassword"
                class="btn btn-lg btn-primary btn-block"
            >Force Update Button</button>
-->


        </div>

    </div>

</template>


<script>
import { onMounted } from 'vue';
//import { computed } from 'vue';
import axios       from 'axios';
//import {useStore } from 'vuex';
//import {useRouter} from 'vue-router';
import {useRoute, useRouter} from 'vue-router';
//import {useAuth  } from '@websanova/vue-auth/src/v3.js';


// import composables files
import useAuthComp from '@/composables/useAuthComp.js';
import useFormUtilsComp from '@/composables/useFormUtilsComp.js';

// import informations and errors messages components
import AltertMessage from '@/components/messages/altertMessage.vue'
import ExceptionMessage from '@/components/messages/exceptionMessage.vue'
import ValidationErrors from '@/components/messages/validationErrors.vue'

export default {

    name: 'Reset-Password-Form',

    components : {
        AltertMessage,
        ValidationErrors,
        ExceptionMessage,
    },

    setup() {
        //const auth     = useAuth();
        //const store    = useStore();
        const router   = useRouter();
        const route = useRoute();
        const authComp = useAuthComp();
        const formUtilsComp = useFormUtilsComp()

        onMounted(() => {
            //
        });

        async function resetPassword() {

            //resetMessages()
            formUtilsComp.useResetErrors()

            await axios({
                method: 'post',
                url: 'auth/reset-password',
                data: {
                    email: authComp.state.form.body.email,
                    token: route.params.token,
                    password: authComp.state.form.body.password,
                    password_confirmation: authComp.state.form.body.password_confirmation,
                },
            })
            .then((res) => {
                formUtilsComp.useMessages(res.data, 'alert-success')

                //Go back to login page
                setTimeout(() => {
                    router.push({name: 'auth-login'})
                }, 2000)
            })
            .catch((err) => {
                formUtilsComp.useSetErrors(err.response.data)
            });
        }

        // data returned to the template view
        return {
            authComp,
            resetPassword,
            formUtilsComp,
        }
    }
}
</script>

<style>
    @import '../../assets/css/signin.css';
</style>
